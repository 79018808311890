<template>
  <main>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      <div style="padding: 30px; display: flex; justify-content: space-around">
        <van-button @click="search" type="primary">查询质押</van-button>
        <van-button @click="getdataList()" type="primary"
          >查询所有数据</van-button
        >
        <van-button @click="addData()" type="danger">添加自定义数据</van-button>
      </div>
      <div class="tableBox">
        <div style="text-align: right">总行数： {{ dataList.length }}</div>
        <table>
          <thead>
            <tr>
              <th style="width: 100px">deeperChain</th>
              <th style="width: 100px">sn</th>
              <th>是否退出</th>
              <th style="width: 100px">创建时间</th>
              <th style="width: 100px">更新时间</th>
              <th style="width: 100px">创世质押</th>
              <th style="width: 100px">basicMining ETH质押</th>
              <th style="width: 100px">basicMining BSC质押</th>
              <th style="width: 100px">basicMining BSC质押V2</th>
              <th style="width: 100px">DeeperChain 质押</th>
              <th style="width: 100px">EasyMining 质押</th>
              <th style="width: 100px">StakingCode</th>
              <th>质押总量</th>
              <th>已释放量</th>
              <th>信用分</th>
              <th>质押信用分</th>
              <th></th>
              <th style="width: 100px">设置Hash</th>
              <th>设置内容</th>
              <th>设置后信用分</th>
            </tr>
          </thead>
          <tbody>
            <tr
              @click="showItem(item)"
              v-for="item in dataList"
              :key="item._id + 'r1'"
            >
              <td style="word-break: break-all">{{ item.address }}</td>
              <td style="word-break: break-all">
                {{ item.deviceId }}
              </td>
              <td>
                {{ item.choice }}
              </td>
              <td>
                {{ item.createdAt }}
              </td>
              <td>
                {{ item.updatedAt }}
              </td>
              <td>
                <div v-if="item.genesis / 1e18">
                  <div>
                    {{ (item.genesis / 1e18).toFixed(5) / 1 }}
                  </div>
                  <div style="word-break: break-all">
                    {{ item.genesisAddress }}
                  </div>
                  <div>
                    {{ $moment(item.genesisReleaseTime * 1000).format() }}
                  </div>
                </div>
              </td>
              <td>
                <div v-if="item.basicETH / 1e18">
                  <div>
                    {{ (item.basicETH / 1e18).toFixed(5) / 1 }}
                  </div>
                  <div style="word-break: break-all">
                    {{ item.basicETHAddress }}
                  </div>
                  <div>
                    {{ $moment(item.basicETHReleaseTime * 1000).format() }}
                  </div>
                </div>
              </td>
              <td>
                <div v-if="item.basicBSC / 1e18">
                  <div>
                    {{ (item.basicBSC / 1e18).toFixed(5) / 1 }}
                  </div>
                  <div style="word-break: break-all">
                    {{ item.basicBSCAddress }}
                  </div>
                  <div>
                    {{ $moment(item.basicBSCReleaseTime * 1000).format() }}
                  </div>
                </div>
              </td>
              <td>
                <div v-if="item.basicBSCV2 / 1e18">
                  <div>
                    {{ (item.basicBSCV2 / 1e18).toFixed(5) / 1 }}
                  </div>
                  <div style="word-break: break-all">
                    {{ item.basicBSCV2Address }}
                  </div>
                </div>
              </td>
              <td>
                <div v-if="item.deeperChainStaking / 1e18">
                  <div>
                    {{ (item.deeperChainStaking / 1e18).toFixed(5) / 1 }}
                  </div>
                </div>
              </td>
              <td>
                <div v-if="item.easy / 1e18">
                  <div>
                    {{ (item.easy / 1e18).toFixed(5) / 1 }}
                  </div>
                </div>
              </td>
              <td>
                <div v-if="item.ethStakingCode">
                  <div>eth: {{ item.ethStakingCode._id }}</div>
                </div>
                <div v-if="item.bscStakingCode">
                  <div>bsc: {{ item.bscStakingCode._id }}</div>
                </div>
              </td>
              <td>
                {{ item.totalStaking }}
              </td>
              <td>
                {{ item.haveReleaseBalance }}
              </td>
              <td>{{ item.credit }}</td>
              <td>{{ item.stakingCredit }}</td>
              <td>
                <van-button
                  v-if="!item.setHash"
                  @click="getLatestStakingData(item)"
                  >获取最新质押信息</van-button
                >
                <van-button v-if="!item.setHash" @click="setStakingCredit(item)"
                  >更新质押信用分</van-button
                >
                <van-button @click="setRelease(item)">设置释放</van-button>
              </td>
              <td style="word-break: break-all">
                {{ item.setHash }}
              </td>
              <td>
                <div v-if="item.releaseSetting">
                  <table>
                    <tbody>
                      <tr>
                        <td>account</td>
                        <td>{{ item.releaseSetting.basicInfo.account }}</td>
                      </tr>
                      <tr>
                        <td>totalReleaseDays</td>
                        <td>
                          {{ item.releaseSetting.basicInfo.totalReleaseDays }}
                        </td>
                      </tr>
                      <tr>
                        <td>startReleaseMoment</td>
                        <td>
                          {{
                            $moment(
                              item.releaseSetting.basicInfo.startReleaseMoment
                            ).format()
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>totalBalance</td>
                        <td>
                          {{
                            item.releaseSetting.basicInfo.totalBalance / 1e18
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>balancePerDay</td>
                        <td>{{ item.releaseSetting.balancePerDay / 1e18 }}</td>
                      </tr>
                      <tr>
                        <td>lastReleaseDay</td>
                        <td>{{ item.releaseSetting.lastReleaseDay }}</td>
                      </tr>
                      <tr>
                        <td>startDay</td>
                        <td>{{ item.releaseSetting.startDay }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
              <td>
                <table v-if="item.creditDataAfter">
                  <tbody>
                    <tr>
                      <td>campaignId</td>
                      <td>{{ item.creditDataAfter.campaignId }}</td>
                    </tr>
                    <tr>
                      <td>credit</td>
                      <td>{{ item.creditDataAfter.credit }}</td>
                    </tr>
                    <tr>
                      <td>currentCreditLevel</td>
                      <td>{{ item.creditDataAfter.currentCreditLevel }}</td>
                    </tr>
                    <tr>
                      <td>initialCreditLevel</td>
                      <td>{{ item.creditDataAfter.initialCreditLevel }}</td>
                    </tr>
                    <tr>
                      <td>rewardEras</td>
                      <td>{{ item.creditDataAfter.rewardEras }}</td>
                    </tr>
                    <tr>
                      <td>stakingCreditAfter</td>
                      <td>{{ item.stakingCreditAfter }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from "../NavBox.vue";

function getStaking(item) {
  if (item.genesis / 1) {
    return {
      staking: item.genesis,
      releaseTime: item.genesisReleaseTime * 1000,
    };
  }
  if (item.basicETH / 1) {
    return {
      staking: item.basicETH,
      releaseTime: item.basicETHReleaseTime * 1000,
    };
  }
  if (item.basicBSC / 1) {
    return {
      staking: item.basicBSC,
      releaseTime: item.basicBSCReleaseTime * 1000,
    };
  }
  if (item.deeperChainStaking / 1) {
    return {
      staking: item.deeperChainStaking,
    };
  }
}

export default {
  components: {
    Navbar,
    NavBox,
  },
  data() {
    return {
      dataList: [],
    };
  },
  mounted() {},
  methods: {
    addData() {
      let deeperChain = prompt("");
      if (deeperChain) {
        this.$fetch({
          url: "/admin/unStakingByDeeperChain",
          method: "POST",
          data: {
            address: deeperChain,
            deviceId: deeperChain,
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "adminToken"
            )}`,
          },
        }).then((res) => {
          console.log(res);
          this.getdataList();
        });
      }
    },
    showItem(item) {
      console.log(item);
    },
    getLatestStakingData(item) {
      this.$fetch({
        url: "/admin/stakingRecord/stakingData",
        method: "PUT",
        data: {
          recordId: item._id,
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        console.log(res);
        this.getdataList();
      });
    },
    setStakingCredit(item) {
      let enterCredit = prompt("credit");
      let credit = enterCredit;
      if (!enterCredit && item.totalStaking <= 1000) {
        credit = 0;
      }
      this.$fetch({
        url: "/admin/setStakingCredit",
        method: "POST",
        data: {
          deeperChain: item.address,
          recordId: item._id,
          credit,
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        console.log(res);
      });
    },
    setRelease(item) {
      if (!item.credit) {
        return alert("no credit");
      }
      if (!item.choice) {
        return alert("choice not match");
      }
      if (!item.totalStaking / 1) {
        return alert("no staking");
      }
      if (!confirm()) {
        return;
      }
      this.$fetch({
        url: "/admin/setReleaseInfo",
        method: "POST",
        data: {
          recordId: item._id,
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        console.log(res);
      });
    },
    search() {
      let searchParams = prompt();
      return this.$fetch({
        url: "/admin/unStakingList",
        params: {
          search: searchParams,
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      })
        .then((res) => {
          this.dataList = res.data;
        })
        .finally(() => {});
    },
    getdataList() {
      this.$fetch({
        url: "/admin/unStakingList",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.dataList = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 10px 30px;
  .leftBox {
    display: flex;
  }
  .rightBox {
    display: flex;
    .formItem {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .titleBox {
        margin-right: 10px;
      }
    }
  }
}
.tableBox {
  padding: 30px;
  font-size: 12px;
  text-align: center;
  table {
    width: 100%;
    th,
    td {
      padding: 5px 2px;
    }
  }
}

.addressBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
</style>